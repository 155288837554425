import styled from 'styled-components';
// import colors from '../../../constants/style/colors';

export const Wrapper = styled.div`
  padding-top: 69px;
`;

export const ContactImg = styled.img`
  width: 100%;
`;

export const QRCodeHolder = styled.div`
  display: flex;
  justify-content: center;
  padding: 100px;
`;
export const QRCodeImg = styled.img``;

export const Address = styled.div`
  padding-top: 30px;

  h1 {
  }

  p {
    line-height: 10px;
  }
`;
