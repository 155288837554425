import React from 'react';
import { Row, Col } from 'antd';
import { useStaticQuery, graphql } from 'gatsby';
import Title from '../../Elements/Title';
import Button from '../../Elements/Button';
import SectionContent from '../../Elements/SectionContent';
import {
  Wrapper,
  ContactImg,
  QRCodeHolder,
  QRCodeImg,
  Address
} from './ContactPerson.style';

export interface Query {
  allContentfulContactPerson: {
    nodes: {
      address: string;
      email: string;
      firstName: string;
      lastName: string;
      phone: string;
      qrCode: {
        file: {
          contentType: string;
          fileName: string;
          url: string;
        };
      };
      skype: string;
      title: string;
      contactPicture: {
        file: {
          contentType: string;
          fileName: string;
          url: string;
        };
      };
      vcard: {
        file: {
          contentType: string;
          fileName: string;
          url: string;
        };
      };
    }[];
  };
}

const query = graphql`
  query {
    allContentfulContactPerson {
      nodes {
        address
        email
        firstName
        lastName
        phone
        qrCode {
          file {
            contentType
            fileName
            url
          }
        }
        skype
        title
        contactPicture {
          file {
            contentType
            fileName
            url
          }
        }
        vcard {
          file {
            contentType
            fileName
            url
          }
        }
      }
    }
  }
`;

export default () => {
  const {
    allContentfulContactPerson: { nodes }
  }: Query = useStaticQuery(query);
  const item = nodes.length && nodes[0];

  if (!item) {
    return <div>no data</div>;
  }

  return (
    <Wrapper>
      <SectionContent>
        <Row gutter={30}>
          <Col md={12}>
            <ContactImg
              src={item.contactPicture.file.url}
              alt={item.contactPicture.file.fileName}
            />
          </Col>
          <Col md={12}>
            <Title align="left">{item.title}</Title>
            <a href={item.vcard.file.url} type="_blank">
              <Button>SAVE MY CONTACT DETAILS</Button>
            </a>
          </Col>
        </Row>
        <Address>
          <h1>{`${item.firstName} ${item.lastName}`}</h1>
          <p>Address: {item.address}</p>
          <p>E-mail: {item.email}</p>
          <p>Tel: {item.phone}</p>
          <p>Skype: {item.skype}</p>
        </Address>
        <QRCodeHolder>
          <QRCodeImg
            src={item.qrCode.file.url}
            alt={item.qrCode.file.fileName}
          />
        </QRCodeHolder>
      </SectionContent>
    </Wrapper>
  );
};
